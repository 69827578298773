<template>
  <!-- 这个即是销售合同详情页面  又是录入结算价格页面 -->
  <div class="salesContractDetailsPageBox page-info-content">
    <Details-page :item-data="cashDepositItemArr" :list-data="cashDepositArr" :table-title="'保证金明细'" :detail-obj="ruleForm" :details-item-arr="detailsItemArr" />
    <h3 class="page-subtitle-shade">
      &nbsp;代采商品列表
    </h3>
    <Table class="tableBox" :item-data="itemData" :list-data="listData" />
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import DetailsPage from '@/components/DetailsPage.vue'
import { agentSupplierDetails } from '@/api/generationMining'
export default {
  components: { DetailsPage, Table },
  data() {
    return {
      agentSettleObj: {},
      detailObj: {},
      cashDepositArr: [],
      cashDepositItemArr: [
        { label: '付款日期(每月)', prop: 'paymentDate', width: 100 },
        { label: '付款比例(%)', prop: 'paymentRate', width: 100 }
      ],
      itemData: [
        { label: '品名', prop: 'goodsName', width: 200 },
        { label: '材质', prop: 'goodsMaterials', width: 200 },
        { label: '规格', prop: 'goodsSpecs', width: 200 },
        { label: '产地', prop: 'goodsProduct', width: 200 },
        { label: '件重（吨）', prop: 'goodsWeights', width: 200 }
      ],
      listData: [],
      ruleForm: {},
      detailsItemArr: [
        { label: '代采商', value: 'cmpName' },
        { label: '合作企业', value: 'partnerName' },
        { label: '企业性质', value: 'plantType', child: this.$store.getters.getDictionaryItem('AgentSupplierInfoNature') },
        { label: '所在地区', value: 'firmAddress' },
        { label: '详细地址', value: 'partnerAddr' },
        { label: '联系人', value: 'contacts' },
        { label: '联系电话', value: 'contactPhone' },
        { link: 'cooContractPath', label: '合作协议', value: 'cooContractName' },
        { link: 'otherFilePath', label: '其他协议', value: 'otherFileName' },
        { label: '结算日期', value: 'settleDate', rightWords: '号', leftWords: '每月' },
        { label: '交收仓库', value: 'deliveryInventoryName' },
        { label: '仓库等级', value: 'inventoryType', child: this.$store.getters.getDictionaryItem('warehouseLevel') },
        { label: '仓库属性', value: 'deliveryType', child: this.$store.getters.getDictionaryItem('warehouseProp') },
        { label: '仓库所在地区', value: 'allAddress' },
        { label: '详细地址', value: 'deliveryAddr' },
        { label: '下游协议模板', value: 'custTemplateName', htmlPage: 'custTemplatePath' }
      ]
    }
  },
  mounted() {
    if (this.$route.query.id) {
      agentSupplierDetails(this.$route.query.id, res => {
        this.ruleForm = { ...res.data }
        this.cashDepositArr = [...res.data.agentSupplierPaymentConfigList]
        this.listData = [...res.data.agentSupplierGoodsList]
        // 渲染城市
        this.ruleForm.firmAddress = this.ruleForm.partnerProvince + this.ruleForm.partnerCity + this.ruleForm.partnerCounty
        if (this.ruleForm.deliveryProvince) this.ruleForm.allAddress = this.ruleForm.deliveryProvince + this.ruleForm.deliveryCity + this.ruleForm.deliveryCounty
      })
    }
  }
}
</script>
<style lang="scss">
.salesContractDetailsPageBox {
  .agentTitle{
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }
  .settlement-price-box{
    display: flex;
    align-items: center;
    margin:  32px 64px;
    >span{
      padding-right: 4px;
      font-size: 14px;
      color: #333;
    }
    .el-input{
      width: 220px;
      margin-right: 32px;
    }
  }
  .cancelButton {
    text-align: center;
  }

  .tableBox {
    margin: 0 auto 18px;
    text-align: center;
    width: calc(100% - 128px);
  }
}
.page-subtitle-shade {
  margin-top: 40px;
  margin-left: 20px;
}
</style>
